import { template as template_1e5fc5e5829e4b1597a230d7779a0a11 } from "@ember/template-compiler";
const WelcomeHeader = template_1e5fc5e5829e4b1597a230d7779a0a11(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
